import React from 'react'
import Slider from './Slider'

export default function Portfolio() {

    const [moveHeader, setMoveHeader] = React.useState(false)

    const handleHeader = () => {
        setMoveHeader(true)
    }
    React.useEffect(() => {
        handleHeader()
    })
    return (
        <>
        <div className="info-section portfolio-section">
            <h1 className={moveHeader ? 'move-header' : null}>Portfolio</h1>
            <Slider />
        </div>
        </>
    )
}
