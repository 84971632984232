import React from 'react'
import MenuItem from './MenuItem'


export default function Menu(props) {

    const [moveName, setMoveName] = React.useState(false)
    const [showTitle, setShowTitle] = React.useState(false)

    const handleMoveName = () => setMoveName(true)
    const handleShowTitle = () => setShowTitle(true)
    
    React.useEffect(() => {
        handleMoveName()
        setTimeout(() => {
            handleShowTitle()
        }, 2000)
    })

    return (
        <>
        {props.containerSize === 'menu-container' && 
        <div id="name">
            <span className={moveName ? 'name-1__center' : 'name-1__side'}>Laura</span> 
            <span className={moveName ? 'name-2__center' : 'name-2__side'}>Heino</span>
        </div> }
        {props.containerSize === 'menu-container' && 
        <div className="web-dev">
            <span className={showTitle && 'web-dev__visible'}>Web Developer</span>
        </div>}


        <div className={props.containerSize}>
            {props.containerSize === 'menu-container__small' && <div id="name__small">Laura Heino</div>}
            <MenuItem childClass="child1" showSection={props.onBioDisplay}>About</MenuItem>
            <MenuItem childClass="child2" showSection={props.onPortfolioDisplay}>Portfolio</MenuItem>
            <MenuItem childClass="child3" showSection={props.onContactDisplay}>Contact</MenuItem>


        </div>
        </>
    )
}
