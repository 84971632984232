import React from 'react'

export default function MenuItem(props) {

    // const [isActive, setIsActive] = React.useState(false)

    const handleClick = () => {
        props.showSection()
        // setIsActive(true)
    }

    return (
        <>
            <div 
            // className={`menu-item ${props.childClass}`} 
            className="menu-item"
            id={props.childClass} 
            // id={isActive ? 'active' : null}
            onClick={handleClick}>{props.children}</div>
        </>
    )
}
