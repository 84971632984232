
import React from 'react'
import './App.css';
import Menu from './components/Menu'
import Bio from './components/Bio'
import Portfolio from './components/Portfolio'
import Contact from './components/Contact'

function App() {


  const [showBio, setShowBio] = React.useState(false) 
  const [showPortfolio, setShowPortfolio] = React.useState(false) 
  const [showContact, setShowContact] = React.useState(false) 
  const [minimenu, setMinimenu] = React.useState(false)

  const minimizeMenu = () => {
    setMinimenu(true)
  }

  const handleShowBio = () => {
    minimizeMenu()
    setShowBio(true)
    setShowContact(false)
    setShowPortfolio(false)
  }

  const handleShowPortfolio = () => {
    minimizeMenu()
    setShowBio(false)
    setShowContact(false)
    setShowPortfolio(true)
  }

  const handleShowContact = () => {
    minimizeMenu()
    setShowBio(false)
    setShowContact(true)
    setShowPortfolio(false)
  }

  return (
    <div className="App">
            <div className="main-container">
            <div className="top">
              <Menu 
                containerSize={minimenu ? 'menu-container__small' : 'menu-container'}
                onBioDisplay={handleShowBio}
                onPortfolioDisplay={handleShowPortfolio}
                onContactDisplay={handleShowContact}
              />
          </div>
          { showBio && <Bio /> }
          { showPortfolio && <Portfolio /> }
          { showContact && <Contact /> }
      </div>
    </div>
  );
}

export default App;
