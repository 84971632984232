import React from 'react'

export default function Contact() {

    const [moveHeader, setMoveHeader] = React.useState(false)

    const handleHeader = () => {
        setMoveHeader(true)
    }
    React.useEffect(() => {
        handleHeader()
    })

    return (
        <>
        <div className="info-section contact-section">
            <h1 className={moveHeader ? 'move-header' : null}>Contact</h1>
            <div className="contact-container">
                <div><img src="/icons/envelope-fill.svg" id="email" height="100" alt="" mailto="lhhein@gmail.com" /></div>
                <div><img src="/icons/github.svg" id="github" height="100" alt="" onclick="location.href = 'https://github.com/sitzelwucht';" /></div>
                <div><img src="/icons/linkedin.svg" id="linkedin" height="100" onclick="location.href = 'https://linkedin.com/in/laura-heino';" alt="" /></div>
                <div><img src="/icons/instagram.svg" id="instagram" height="100" onclick="location.href = 'https://instagram.com/sitzelwucht';" alt="" /></div>
            </div>
        </div>
        </>
    )
}
