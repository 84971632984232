import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper/core';
import 'swiper/swiper.scss';
import projects from '../projects.json'

SwiperCore.use([Navigation, Pagination, Scrollbar]);

export default function Slider() {

    const [width, setWidth] = React.useState(window.innerWidth)

    const handleResize = () => {
        setWidth(window.innerWidth)
      }
    
      React.useEffect(() => {
        window.addEventListener('resize', handleResize)
      }, []) 

    return (
        <div>

            <Swiper
            tag="section"
            navigation
            pagination
            spaceBetween={110}
            slidesPerView={width < 1350 ? width < 850 ? 1 : 2 : 3}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            >
            {projects.map((item, idx) => {
                return <SwiperSlide key={idx} tag="li">
                <div className="project-container">
                
                    <div className="project-description">{item.description}</div>

                    <div>
                        <img src={item.image} className="project-image" height="175" alt="" />
                    </div>

                    <div className="project-links">
                        <div><a href={item.repoTarget}>repository</a></div>
                        {item.deploTarget && <div><a href={item.deploTarget}>deployment</a></div>} 
                    </div>
                  
                </div>
                </SwiperSlide>
            }) 
            }
            </Swiper>
        </div>
    )
}
