import React from 'react'
import BioParagraph  from './BioParagraph'

export default function Bio() {

    const [moveHeader, setMoveHeader] = React.useState(false)
  
    const [paraPos, setParaPos] = React.useState() 
    const handleHeader = () => {
        setMoveHeader(true)
    }
    React.useEffect(() => {
        handleHeader()
    })
    return (
        <>
        <div className="info-section bio-section">
        <a className="cv-badge" href="https://drive.google.com/file/d/1jcIz4FgNE-3-ERUu7rF2HCbrYpqxoqzZ/view?usp=sharing">CV</a>
            <h1 className={moveHeader ? 'move-header' : null}>About</h1>
            <div className="bio-container">
            <div className="two-sides">
                <div className="left">
                    <BioParagraph class="test">In Berlin, Germany since 2016, by way of Ireland and Norway. Originally from Finland.</BioParagraph>
                    <BioParagraph>I started learning programming in late 2019 with JavaScript, and learned the basics of Java and Python the following year.
                    Eventually moved onto on fullstack web development with the MERN stack.</BioParagraph>
                    <BioParagraph>
                        <img src="/icons/nodejs.png" alt="" />
                        <img src="/icons/html.png" alt="" />
                        <img src="/icons/java.png" alt="" />
                        <img src="/icons/python.png" alt="" />
                        <img src="/icons/json.png" alt="" />
                        <img src="/icons/npm.png" alt="" />
                        <img src="/icons/react.png" alt="" />
                        <img src="/icons/sass.png" alt="" />
                        <img src="/icons/typescript.png" alt="" />
                        <img src="/icons/ubuntu.png" alt="" />
                        <img src="/icons/linux.png" alt="" />
                        <img src="/icons/mongodb.png" alt="" />
                        <img src="/icons/css.png" alt="" />
                    </BioParagraph>

                </div>
                
                <div className="right">
                    <BioParagraph>Working as a Frontend Developer since 2021. Made the move after working in customer and tech support for several years, 
                    most recently in a fintech startup in Berlin.</BioParagraph>
                    <BioParagraph>I have my academic background in linguistics and translation, as well as social sciences. I've always been interesed in the human mind and its various creations.</BioParagraph>
                    <BioParagraph>My hobbies besides tech-related topics include photography, houseplants, psychology, videogames, languages, movies, books and yoga.</BioParagraph>
                </div>
            </div>



            {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum a pulvinar nibh. Curabitur sit amet dui consequat, cursus est nec, accumsan elit. Integer viverra blandit fermentum. Nunc id volutpat mauris. Integer ullamcorper magna sed efficitur molestie. Duis varius sollicitudin libero nec vestibulum. Sed tempus hendrerit metus, et luctus ante aliquet tincidunt. Aliquam mollis sem fringilla mauris lobortis aliquam consequat et orci. Nam non vehicula neque. Fusce lacinia varius erat at finibus. Duis sit amet ipsum quis quam cursus cursus eget eu magna. Integer ut tellus sit amet nulla vulputate rutrum quis vitae magna. Pellentesque egestas felis in libero gravida, eget lobortis dolor lacinia. Phasellus dictum gravida massa eu mollis. Ut imperdiet augue id tortor mollis facilisis. Nunc ac lorem sit amet purus laoreet ullamcorper vitae eu nunc.

            </p>

            <p>

            Ut nisl sapien, suscipit a volutpat ut, faucibus a tortor. Nam euismod pulvinar mauris, ut sollicitudin neque vehicula et. Praesent posuere ligula metus, vitae luctus nisl suscipit vitae. Praesent vel enim sed felis euismod tempor. Suspendisse volutpat blandit mollis. Aliquam eget risus varius, consequat erat a, sodales neque. Donec convallis tincidunt tellus eget aliquet. Donec convallis placerat eros nec faucibus. Duis nisl nibh, feugiat ut tincidunt eget, lobortis ac magna. Sed finibus purus ac diam maximus, nec ultrices nisi maximus.

            </p>
            <p>

            Aenean sed commodo dui. Phasellus eget gravida metus, nec rutrum tellus. Sed semper sapien in pretium posuere. Nam ex dolor, blandit a placerat ac, condimentum non mauris. Duis hendrerit varius nunc a lobortis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aliquam luctus dapibus velit, id maximus nisi sagittis at.

            </p>
            In dolor ante, volutpat vitae gravida in, posuere sed augue. Cras fermentum erat id sodales malesuada. Mauris mattis imperdiet velit in porta. Pellentesque viverra justo eu ligula venenatis, sit amet rhoncus tellus tristique. Mauris nec nulla lorem. Fusce eget pharetra mauris. Nullam cursus ullamcorper leo, commodo blandit metus. Nam gravida justo risus, quis venenatis magna suscipit sed. Suspendisse lobortis bibendum arcu ac pharetra.

            <p>

            Vestibulum ullamcorper ultrices neque eget tempus. Integer elementum urna feugiat, pellentesque libero sit amet, laoreet orci. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Maecenas purus sem, gravida sit amet aliquam vitae, auctor vel felis. Aenean vitae tortor urna. Integer egestas, mauris at egestas lobortis, velit ligula interdum urna, a consectetur nisi nunc vel eros. Suspendisse vestibulum ex finibus magna gravida auctor. Praesent tristique maximus mauris tempor aliquam. Cras vel est lorem. Duis mattis augue ut diam consectetur eleifend.
                
            </p>    */}
            </div>
            </div>
        </>
    )
}
