import React, { useState, useEffect } from 'react'

export default function BioParagraph(props) {

    const paragraph = React.useRef()

    const [position, setPosition] = useState()
    const [show, setShow] = useState(true)

    const handleScroll = () => {
        const dist = window.innerWidth < 768 ? 170 : 375
        setPosition(paragraph.current.getBoundingClientRect())
        if(position && position.top < dist) {
                setShow(false)
            }
        
        else setShow(true)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true)
        return () => window.removeEventListener('scroll', handleScroll, true)
    })

    return (
        <>
             <p 
            ref={paragraph}
            className={show ? null : 'fade' }
            position={props.pos}>{props.children}</p>
        </>
    )
}
